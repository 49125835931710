body {
  margin: 0;
  font-family: "'Urbane'", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  text-align: center;
  min-height: 100vh;
}

.header {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header a {
  margin: 1rem;
}

/* Disable password toggle and input clear on edge */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
