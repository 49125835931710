.allActivityNoDataList {
  background-color: #fff;
  padding: "16px 24px";
}
.allActivityList {
  display: "block";
  background-color: #fff;
  border-radius: 8px;
  padding: "16px 16px";
  margin: "10px";
  border: "#FFF";
}
button.Mui-disabled:disabled {
  background: #cdcdcd;
  color: #fff;
}
.infomain{
  position: relative;
}
.infoIcon{
  position: absolute;
  top: 0px;
  left: 80px;
  cursor: pointer;
}
.bt{

margin: 0 auto !important;
width: 72px;
height: 36px;
margin-top: 20px !important;
margin-bottom: 20px !important;
/* Neutral/0 */
background: #FFFFFF !important;
/* Primary/500 */
border: 1px solid #592EE5 !important;
border-radius: 30px !important;
font-weight: 400 !important;

text-transform: uppercase !important;
font-size: 12px !important;
}
.infoText{
  font-size: 12px !important;
  font-style: normal !important;
font-weight: 400 !important;
line-height: 18px;
color: #202020 !important;
width: 276px !important;

}
.headericon{
  margin: 0 auto;
  margin-top: 24px !important;
  margin-bottom: 16px !important;

}
