.paymentReviewDialog {
  .paymentReviewDialogContent {
    height: 300px !important;
  }
  .paymentReviewDialogActions {
    button {
      height: 35px;
    }
  }
}
