.headerNoResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.unMarging {
  margin: 0;
}
.Link {
  cursor: pointer;
  text-decoration: underline;
}
