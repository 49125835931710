.MuiDialogContent-root {
  height: 550px !important;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  display: flex;
  > div {
    width: 100%;
    margin: auto;
    img {
      width: 85%;
    }
    .react-multi-carousel-list {
      overflow-y: auto;
      overflow-x: hidden;
      position: static;
      padding: 0 65px;
      @media only screen and (max-width: 600px) {
        padding: 0 21px !important;
      }
    }
    .carouselNextBtn {
      right: calc(4% + 1px);
      position: absolute;
      outline: 0;
      transition: all 0.5s;
      border-radius: 5px;
      z-index: 1000;
      border: 0;
      width: 50px;
      height: 40px;
      opacity: 1;
      background-color: #fff;
      color: #592ee5;
      cursor: pointer;
      bottom: 20px;
      font-family: Urbane, Helvetica, sans-serif;
      font-weight: 500;
      font-size: 0.8125rem;
    }
    .carouselNextBtn:hover,
    .carouselPreviousBtn:hover {
      background-color: rgba(89, 46, 229, 0.04);
    }
    .carouselPreviousBtn {
      color: #592ee5;
      left: calc(4% + 1px);
      position: absolute;
      outline: 0;
      border-radius: 5px;
      transition: all 0.5s;
      z-index: 1000;
      border: 0;
      background-color: #fff;
      width: 70px;
      height: 43px;
      opacity: 1;
      bottom: 20px;
      font-family: Urbane, Helvetica, sans-serif;
      font-weight: 500;
      font-size: 0.8125rem;
      cursor: pointer;
    }
    .react-multi-carousel-dot-list {
      position: absolute;
      bottom: 28px;
      display: flex;
      left: 6px;
      right: 0;
      justify-content: center;
      margin: auto;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
    }
  }
}
