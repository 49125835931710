/* .dropMenu .css-g8le3h-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
    background-color: #ebedfa !important;
}
.textFiled_bg .css-24rejj-MuiInputBase-input-MuiOutlinedInput-input{
    background-color: #ebedfa !important;

} */
.bankinfo .dropMenu .MuiSelect-select,.bankinfo .textFiled_bg .MuiInputBase-input {
    background-color: #ebedfa !important; 
}
