.head {
    width: 900px;
    margin-left: -71px;
    margin-top: -60px;
    border-radius: undefinedpx;
    text-align: center !important;
  }
  .content {
    font-family: Urbane;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }
  .mission {
    font-family: Urbane;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }
  .listelements {
    display: "block";
    background-color: #fff;
    border-radius: 8px;
    padding: "26px 26px";
    height: "20px";
    margin: "10px";
    border: "#FFF";
    box-shadow: rgba(0, 0, 0, 0.1) 2.4px 2.4px 5.2px;
    text-align: left !important;
  }
  .listgap {
    margin-left: 10px;
  }
  .link {
    text-decoration: none;
    margin-left: 10px;
  }