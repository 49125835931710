.mainContaner{
    text-align: left !important;
}
.savingcards{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 18px;

}

.userCard{
        font-size: 14px;
        font-weight: 500;
        margin-top: 20px  
}
.cardscontainer{
    background: #FFFFFF;
    /* Neutral/400 */
    
    border: 1px solid #C0C0C0;
    border-radius: 8px;
padding-left: 10px;
margin-bottom: 10px;
position: relative;
}
.deleteIcon{
    position: absolute;
    right: 0;
    top:7px;
    cursor: pointer;
}
.noSavedCards{
    font-size: 14px !important;
    font-weight: 400;
    color:rgb(181 167 167);
    padding-left: 20px;

}

.cardscontainer .css-lbt9ii-MuiTypography-root{
    font-size: 14px !important;
    font-weight: 200;
    color: #000000;
}
.cardscontainer  .css-1jy61ek-MuiButtonBase-root-MuiRadio-root{
    color: #8A67FE !important;
}
.disabled-icon {
    color: gray; /* Change the color to make it look disabled */
    pointer-events: none; /* Disable mouse events */

    position: absolute;
    right: 0;
    top:7px;
    cursor: pointer;
}