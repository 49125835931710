.subheadingalign{
margin-top: 30px;
/* width: 1000px; */
font-weight: 300;
top: 189px;
border-radius: undefinedpx;
text-align: left !important;
}
.subheadingname{
    text-align: left !important;
    margin-top: 10px;
    width: 700px;
    top: 189px;
    color:#592EE5;
    font-weight: bolder;
    border-radius: undefinedpx;
    }
.subheadingtext{height: 80px;
    width: 700px;
    left: 24px;
    }
.subheading{
text-align: left !important;
height: 24px;
width: 320px;
top: 109px;
font-weight: lighter;
border-radius: undefinedpx;
}