.mainHeader{
    font-size: 32px !important;
    color: #091F82;
    font-weight: 400 !important;
    margin-bottom: 30px !important;
    text-transform: uppercase;


}
.button-login{
    padding-left: 0px !important;
    width: 0 auto;
    margin-top: 40px !important;
    margin-bottom: 20px !important;
}
.continueButton{
    background: linear-gradient(167.98deg, #8A67FE -19.44%, #592EE5 108.74%) !important;
border-radius: 46px !important;
font-weight: 300 !important;
font-size: 12px !important;
text-align: center !important;
letter-spacing: 0.05em !important; 
text-transform: uppercase !important;
   
}


.bottomText{
    font-weight: 300 !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    color: #091F82;
    margin-bottom: 10px !important;  
 
}
.maincontainer{
text-align: left !important;
}
.verifyText{
    font-size: 16px !important;
line-height: 30px !important;
font-weight: 350 !important;
width: 260px !important; 
margin: '0 auto !important';

/* Primary/400 */

color: #6740E8;
margin-bottom: 40px  !important;
}
.checkemail{
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #6740E8;
}
.reSendButton{
height: 46px !important;
background: #FFFFFF !important;
border: 1px solid #592EE5 !important;
border-radius: 46px !important;
margin-top: 10px !important;
}