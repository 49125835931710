.temsText{
    font-weight: 700;
font-size: 14px;
padding-top: 5px;
}
.temsInner{
    font-weight: 300;
font-size: 14px;
line-height: 20px;
}
.mainContainer{
    flex-grow: 1; padding-top: 5;
    margin-top: 50px;

}
.temsSeprator{
    height: 4px;
    background: #D9D9D9;
    margin-top: 20px;
}
