.noRecieptTypograpy{
    font-size:25px !important;
    margin-top:6px !important;
}
.contentTypograpy{
    text-align: center;
    font-size: 14px !important;
    margin-bottom: 96px !important;
}
.boxStyle{
    align-items: center;
}