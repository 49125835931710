.conatactSupportHeader {
  background: linear-gradient(rgba(96, 67, 222, 0.2), rgba(96, 67, 222, 0));
  width: 101px;
  height: 101px;
  margin: 0 auto;
  border-radius: 50%;
}
.conatactSupport_img {
  margin-top: 30px;
  width: 62px;
  height: 36px;
}
.messagebutton {
  background: #33139a !important;
  border-radius: 46px !important;
  width: 477px;
  height: 60px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
}
.conactusButton {
  background: #ffffff;
  border: 1px solid #33139a;
  border-radius: 46px;
  width: 477px;
  height: 60px;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
}
.mobileButton {
  background: #ffffff;
  border: 1px solid #33139a;
  border-radius: 46px;
  width: 477px;
  height: 60px;
  margin: 0 auto;
  cursor: pointer;
  margin-top: 20px;
  position: relative;
}
.imgIcon {
  position: absolute;
  left: 50px;
  top: 20px;
}

.contactUsText {
  padding-top: 20px;
  font-style: normal;
  font-weight: 500px;
  font-size: 16px;
  color: #592ee5;
  line-height: 24px;
  text-transform: capitalize;
}
.messageText {
  padding-top: 20px;
  font-style: normal;
  font-weight: 500px;
  font-size: 16px;
  color: #ffffff;
  line-height: 24px;
  text-transform: capitalize;
  cursor: pointer;
}

.header {
  color: #202020;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
}
.contactHeader {
  color: #202020;
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: left;
}

.mainheader {
  margin-bottom: 15px !important;
  font-size: 20px;
  font-weight: 600px;
}
.numberunderline {
  padding-top: 10px;
}

@media (max-width: 600px) {
  .conactusButton {
    width: 100%;
  }
  .mobileButton {
    width: 100%;
  }

  .messagebutton {
    width: 100%;
  }
}
