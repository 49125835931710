.mainContainer{
    float: left !important;
}
.textContainer{
    float: left !important;

}
.mainTableContainer{
    margin-left: -200px!important; margin-right:-170px !important;
}

@media only screen and (max-width: 768px){
.mainTableContainer{
    margin-left: 0px!important; margin-right:0px !important;
}

}
