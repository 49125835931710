.btn{
    text-align: left !important;
    margin-left: 100px;
    }
    .contactUsText{
        padding-top: 20px; 
        font-style: normal;
        font-weight: 500px;
      font-size: 16px;
      color: #592EE5;
      line-height: 24px;
      text-transform: capitalize;
      }
      .messagebutton{
        background: #33139A !important;
        border-radius: 46px !important;
        line-height: 2.5 !important;
        font-weight: 100 !important;
         font-size: 15px !important;
        width:91px;
        height: 40px;
        margin:0 auto;
        position:absolute;
        margin-bottom: 20px;
        text-align: center;
      }